import FormDialog from '@components/form/dialog_v2'
import LoadingPopup from '@components/form/LoadingDialog'
import FormSignaturePad from '@components/form/signaturePad'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import { FormPartComponents, KeyValPair } from '@models/common'
import {
  Box,
  Typography,
  Divider,
  Button,
  BoxProps,
  Grid,
  Stack,
  Autocomplete,
  TextField,
  IconButton,
  useMediaQuery,
  Drawer,
  Paper,
  CircularProgress,
  InputBase,
  StepIconProps,
  Step,
  StepLabel,
  Stepper,
  StepConnector,
  stepConnectorClasses,
  Tooltip,
} from '@mui/material'
import { FormContext } from '@providers/formStateProvider'
import { useState, useContext, useRef, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import commonStyles from '@styles/common.module.scss'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Comment, FormHistory, NotifyList } from '@services/model/form/form.model'
import { GlobalContext, GlobalStateProps } from '@providers/globalStore'
import GetValueWithKey from '@utils/getValueWithKey'
import moment from 'moment'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import avatar from '../assets/img/dummyAvatar.png'
import useAPIFetch from './useAPIFetch'
import DialogButton from '@components/button/DialogButton'
import { useSnackbar } from 'notistack'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

function RoleItem(props: BoxProps) {
  const { sx, ...other } = props
  return (
    <Box
      sx={{
        mr: 1,
        p: 1,
        border: '1px solid',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  )
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(1),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    marginLeft: 'min(10%, 200px);',
    marginRight: 'min(10%, 200px);',
  },
  height: '100%',
  flexDirection: 'column',
}))

const FormTitleTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(0),
}))

const PartTitleTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  paddingTop: theme.spacing(0),
}))

const StyledDivider = styled(Divider)(() => ({
  '&::before, &::after': {
    borderColor: 'black',
  },
}))

const ButtonGroupBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

const SwitcherButton = styled(Button)(() => ({
  color: '#000000',
  '&:hover': {
    backgroundColor: '#e6e6e6',
  },
}))

const CommentItemTitle = styled(Box)<{ backgroundColor?: string }>(({ backgroundColor }) => ({
  minHeight: '29px',
  paddingLeft: '20px',
  paddingRight: '20px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: backgroundColor ?? '#fff',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  fontWeight: 'bold',
}))

const CommentItemContent = styled(Grid)({
  minHeight: '68px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
})

type DialogContextProps = {
  isOpen: boolean
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

type useFormSwitcherProps = {
  title: string
  components: FormPartComponents[]
  startStep?: number

  formOnSubmit?: (
    event: any,
    isReject: boolean,
    notifyUserList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => Promise<void>

  formOnSave?: (event: any) => Promise<void>
  formOnDelete?: (
    event: any,
    notifyUserList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => Promise<void>
  formOnCancel?: (
    event: any,
    isReject: boolean,
    notifyUserList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => Promise<void>
  formOnAppeal?: (
    event: any,
    notifyUserList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => Promise<void>

  formOnLoadNotifyList?: (isRejcet: boolean) => Promise<NotifyList | undefined>
  formOnLoadCommentList?: () => Promise<Comment[]>
  formOnSubmitComment?: (comment: string) => Promise<void>
  formOnLoadFormHistoryList?: () => Promise<FormHistory[]>

  isLoading?: boolean
  disableSave?: boolean
  disableDelete?: boolean
  showTrashBin?: boolean
  disableComment?: boolean
  disableComplete?: boolean
  disableExport?: boolean

  formOnNext?: (event: any) => Promise<void>
  formOnBack?: (event: any) => Promise<void>

  navigateBack?: (event: any) => void
  onSubFormComplete?: () => void
  onExportDocument?: (event: any) => void

  confirmText?: string
  showAppealButton?: boolean
  approveText?: string
  approveRequired?: boolean
  endOfFlow?: boolean
  formCanCancel?: boolean
  deleteRequireWorkflow?: boolean

  isMounted?: boolean
}

const CommentInput = (props: { formOnSubmitComment?: (comment: string) => Promise<void> }) => {
  const { t } = useTranslation()
  const [comment, setComment] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCommentChange = (event: any) => {
    setComment(event.target.value)
  }

  const handleCommentSubmit = async (event: any) => {
    event.preventDefault()
    if (props.formOnSubmitComment && !isLoading) {
      setIsLoading(true)
      await props.formOnSubmitComment(comment)
      setComment('')
    }
    setIsLoading(false)
  }

  return (
    <Box
      component="form"
      sx={{ display: 'flex', marginTop: 1, marginBottom: 0 }}
      onSubmit={handleCommentSubmit}>
      <InputBase
        id="comment-input"
        sx={{
          width: '100%',
          padding: 1,
        }}
        placeholder={t('Please enter your comment ...')}
        onChange={handleCommentChange}
        value={comment}
      />
      <IconButton type="submit">
        <ChatBubbleIcon />
      </IconButton>
    </Box>
  )
}

const useFormSwitcher = function (
  props: useFormSwitcherProps,
): [FormSwitcher: () => JSX.Element, handleOnComplete: () => void] {
  const { state: globalState } = useContext(GlobalContext)
  const { contractNoId } = globalState
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  // startStep checking
  const partLetterNumRef = useRef<number>(65)
  const InitDialogContent: DialogContextProps = {
    isOpen: false,
    title: <></>,
  }

  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)

  const { dispatch } = useContext(FormContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  let initStep = 0
  if (props.startStep) {
    if (props.startStep >= 0 && props.startStep < props.components.length) {
      initStep = props.startStep
    } else {
      console.error('startStep from props must be within range of components')
    }
  }
  const [activeStep, setActiveStep] = useState<number>(initStep)

  useEffect(() => {
    partLetterNumRef.current += initStep
  }, [])

  useEffect(() => {
    if (props.isMounted) {
      initStep = props.components.findIndex((x, idx) => x.disabled !== true && idx >= initStep)
      setActiveStep(initStep)
    }
  }, [props.isMounted])

  const handleNext = async (event: any) => {
    const nextStep = props.components.findIndex((x, idx) => !x.disabled && idx > activeStep)
    if (nextStep < 0 || nextStep >= props.components.length) {
      if (props.formOnSubmit === undefined && props.formOnSave) {
        props.formOnSave(event)
        return
      } else {
        handleOnComplete()
      }
    } else {
      const customOnNext = props.components[activeStep].onNext
      if (customOnNext) {
        await customOnNext(event)
      }
      if (props.formOnNext) {
        await props.formOnNext(event)
      }

      partLetterNumRef.current += 1
      setActiveStep(nextStep)
    }
  }

  const handleBack = async (event: any) => {
    let prevStep = -1
    for (let i = activeStep - 1; i >= 0; i--) {
      if (!props.components[i].disabled) {
        prevStep = i
        break
      }
    }

    if (prevStep >= 0) {
      const customOnBack = props.components[activeStep].onBack
      if (customOnBack) await customOnBack(event)

      if (props.formOnBack) {
        await props.formOnBack(event)
      }

      partLetterNumRef.current -= 1
      setActiveStep(prevStep)
    }
  }

  function IconItem(iconProps: BoxProps) {
    const { sx, ...other } = iconProps
    return (
      <Box
        sx={{
          m: 1,
          color: 'dark',
          border: '1px solid',
          borderColor: 'transparent',
          fontSize: '0.875rem',
          ...sx,
        }}
        {...other}
      />
    )
  }

  const handleOnDelete = (event: any) => {
    if (props.formOnDelete) props.formOnDelete(event)
  }

  const handleDiscard = (event: any) => {
    if (props.navigateBack) {
      props.navigateBack(event)
    } else {
      dispatch({ type: 'clear' })
      const pathList = window.location.pathname.split('/')
      navigate(`/${pathList[1]}/all-record`)
    }
  }

  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }

  const handleBackBtn = () => {
    setDialogContext({
      isOpen: true,
      title: (
        <Typography variant="body1">
          {t('Do you want to save changes to this Form Record?')}
        </Typography>
      ),
      children: <Grid></Grid>,
      buttons: (
        <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
          <DialogButton
            disabled={props.disableSave || props.endOfFlow}
            onClick={(event: any) => {
              if (props.onSubFormComplete) {
                handleOnComplete()
              } else if (props.formOnSave) {
                props.formOnSave(event).then(() => {
                  const pathList = window.location.pathname.split('/')
                  navigate(`/${pathList[1]}/all-record`)
                })
              }
            }}>
            {t('Save')}
          </DialogButton>
          <DialogButton onClick={handleDiscard}>{t('Discard')}</DialogButton>
          <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
        </Stack>
      ),
    })
  }

  const userListRef = useRef<KeyValPair[]>([])
  const signatureBase64Ref = useRef<string | undefined>()
  const commentInApproveReject = useRef<string | undefined>()

  const handleOnApprove = (event: any) => {
    changeContextForConfirmation(t('Approve'), '#63C433', false, true, props.formOnSubmit)
  }
  const handleOnReject = (event: any) => {
    changeContextForConfirmation(t('Reject'), '#E24615', true, true, props.formOnSubmit)
  }
  const handleOnCancel = (event: any) => {
    changeContextForConfirmation(t('Cancel'), '#878686', false, true, props.formOnCancel)
  }

  const handleOnArchive = (event: any) => {
    changeContextForConfirmation(t('Delete'), '#878686', false, true, undefined, props.formOnDelete)
  }

  const { enqueueSnackbar } = useSnackbar()

  const changeContextForConfirmation = async (
    headerTitle: string,
    headerColor: string,
    isReject: boolean,
    requireComment: boolean,
    onConfirmCallback?: (
      event: any,
      isReject: boolean,
      notifyList?: string[],
      signatureBase64?: string,
      submissionComment?: string,
    ) => Promise<void>,
    onDeleteCallback?: (
      event: any,
      notifyList?: string[],
      signatureBase64?: string,
      submissionComment?: string,
    ) => Promise<void>,
  ) => {
    var defaultNotifyList: NotifyList = { roles: [], users: [] }
    let hasError = false
    if (props.formOnLoadNotifyList) {
      await props
        .formOnLoadNotifyList(isReject)
        .then((x) => {
          if (x === undefined) hasError = true
          else defaultNotifyList = x
        })
        .catch((err) => {
          enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
          hasError = true
        })
    }

    if (hasError) return

    var defaultNotifyUserList =
      globalState.userMetaList?.filter((x) => {
        return defaultNotifyList?.users?.includes(x.key)
      }) || []

    userListRef.current = defaultNotifyUserList


    setDialogContext({
      isOpen: true,
      toolbarStyle: headerColor ? { backgroundColor: headerColor, color: 'white' } : undefined,
      title: headerTitle ? <Typography variant="body1">{headerTitle}</Typography> : undefined,
      children: (
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={matchDownMd ? 12 : 2} sx={{ p: 1 }}>
            {t('Submit to')}
          </Grid>

          <Grid item xs={matchDownMd ? 12 : 10} sx={{ p: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}>
              {(
                globalState.roleMetaList?.filter((x) => {
                  return defaultNotifyList?.roles?.includes(x.key)
                }) || []
              )?.map((x, idx) => {
                return <RoleItem key={`ri_${idx}`}>{x.value}</RoleItem>
              })}
            </Box>
          </Grid>

          <Grid item xs={matchDownMd ? 12 : 2} sx={{ p: 1 }}>
            {t('Notify')}
          </Grid>
          <Grid item xs={matchDownMd ? 12 : 10} sx={{ p: 1 }}>
            <Autocomplete
              fullWidth
              disablePortal
              multiple
              defaultValue={defaultNotifyUserList}
              getOptionLabel={(option: KeyValPair) => {
                return option.value
              }}
              options={globalState.userMetaList?.filter(({ contractNoId: userContracts = [] }) => userContracts.includes(contractNoId)) || []}
              onChange={(event: any, newValue: KeyValPair[]) => {
                userListRef.current = newValue
              }}
              {...(matchDownMd && {
                sx: {
                  height: '100px',
                  overflowY: 'auto',
                  '&.MuiFormControl-root': {
                    height: 'inherit',
                  },
                  '::-webkit-scrollbar': {
                    width: '20px',
                  },
                },
              })}
              renderInput={(params) => {
                const { InputProps, ...restParams } = params
                const { startAdornment, ...restInputProps } = InputProps
                return (
                  <TextField
                    {...restParams}
                    InputProps={{
                      ...restInputProps,
                      startAdornment: (
                        <div
                          style={{
                            maxHeight: '250px',
                            overflowY: 'auto',
                          }}>
                          {startAdornment}
                        </div>
                      ),
                    }}
                  />
                )
              }}
            />
          </Grid>

          {requireComment ? (
            <>
              <Grid item xs={matchDownMd ? 12 : 2} sx={{ p: 1 }}>
                {t('Comment')}
              </Grid>
              <Grid item xs={matchDownMd ? 12 : 10} sx={{ p: 1 }}>
                <Box
                  component="form"
                  sx={{
                    display: 'flex',
                    marginTop: 1,
                    marginBottom: 0,
                    border: '1px solid #c4c4c4',
                  }}>
                  <InputBase
                    id="comment-input"
                    sx={{
                      width: '100%',
                      padding: 1,
                    }}
                    placeholder={t('Please enter your comment ...')}
                    onChange={(event) => {
                      commentInApproveReject.current = event.target.value
                    }}
                  />
                </Box>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid item xs={matchDownMd ? 12 : 2} sx={{ p: 1 }}>
            {t('Signature')}
          </Grid>
          <Grid
            item
            xs={matchDownMd ? 12 : 10}
            sx={{ p: 1, border: '1px solid', overflow: 'hidden' }}>
            <FormSignaturePad
              sx={{ width: '650px' }}
              onChange={(newValue: string) => {
                signatureBase64Ref.current = newValue
              }}
            />
          </Grid>
        </Grid>
      ),
      buttons: (
        <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
          {props.showAppealButton && (
            <DialogButton
              disabled={props.disableSave}
              onClick={async (event) => {
                if (props.formOnAppeal) {
                  await props.formOnAppeal(
                    event,
                    userListRef.current.map((x) => x.key),
                    signatureBase64Ref.current,
                    commentInApproveReject.current,
                  )

                  handleDialogClose()
                }
              }}>
              {t('Appeal')}
            </DialogButton>
          )}

          <DialogButton
            disabled={props.disableSave}
            onClick={async (event) => {
              if (onConfirmCallback) {
                await onConfirmCallback(
                  event,
                  isReject,
                  userListRef.current.map((x) => x.key),
                  signatureBase64Ref.current,
                  commentInApproveReject.current,
                )

                handleDialogClose()
              } else if (onDeleteCallback) {
                await onDeleteCallback(
                  event,
                  userListRef.current.map((x) => x.key),
                  signatureBase64Ref.current,
                  commentInApproveReject.current,
                )
              }
            }}>
            {props.confirmText ?? t('Confirm')}
          </DialogButton>
          <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
        </Stack>
      ),
    })
  }

  const handleOnComplete = () => {
    if (props.onSubFormComplete) {
      props.onSubFormComplete()
      handleDialogClose()
      return
    }

    signatureBase64Ref.current = undefined
    commentInApproveReject.current = undefined

    if (props.approveRequired === true)
      setDialogContext({
        isOpen: true,
        title: <></>,
        children: (
          <Box sx={{ padding: '10px 35px' }}>
            <Typography variant="body1">{t('Would you like to proceed?')}</Typography>
          </Box>
        ),
        buttons: (
          <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
            <DialogButton onClick={handleOnApprove} disabled={props.disableSave}>
              {t(props.approveText ?? 'Approve')}
            </DialogButton>
            <DialogButton onClick={handleOnReject} disabled={props.disableSave}>
              {t('Reject')}
            </DialogButton>
            <DialogButton onClick={handleDialogClose} disabled={props.disableSave}>
              {t('Cancel')}
            </DialogButton>
          </Stack>
        ),
      })
    else changeContextForConfirmation('', '', false, false, props.formOnSubmit)
  }

  const handleDelete = (event: any) => {
    if (props.onSubFormComplete) {
      handleDiscard(event)
    } else {
      setDialogContext({
        isOpen: true,
        title: (
          <Typography variant="body1">
            {t('Are you sure you want to permanently delete the current form?')}
          </Typography>
        ),
        children: <Grid></Grid>,
        buttons: (
          <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
            <DialogButton onClick={props.deleteRequireWorkflow ? handleOnArchive : handleOnDelete}>
              {t('Yes')}
            </DialogButton>
            <DialogButton onClick={handleDialogClose}>{t('No')}</DialogButton>
          </Stack>
        ),
      })
    }
  }

  const handleCancel = (event: any) => {
    setDialogContext({
      isOpen: true,
      title: (
        <Typography variant="body1">
          {t('Are you sure you want to cancel the current form?')}
        </Typography>
      ),
      children: <Grid></Grid>,
      buttons: (
        <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
          <DialogButton onClick={handleOnCancel}>{t('Yes')}</DialogButton>
          <DialogButton onClick={handleDialogClose}>{t('No')}</DialogButton>
        </Stack>
      ),
    })
  }
  const containerRef = useRef<HTMLDivElement>(null)

  const ContentItem = () => {
    const [contentDrawerOpen, setContentDrawerOpen] = useState<boolean>(false)

    const handleContentDrawerClick = () => {
      setContentDrawerOpen((open) => !open)
    }

    const ContentDrawer = (props: {
      refContainer?: Element | (() => Element | null) | null | undefined
      contentDrawerOpen: boolean
      handleContentDrawerClick: () => void
      contents: {
        title: string
        disabled?: boolean
      }[]
      onContentClick: (contentIndex: number) => void
      currentStep: number
      width: number | string
    }) => {
      const { t } = useTranslation()
      const theme = useTheme()
      const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
      let partLetterNumRef = 64

      return (
        <Drawer
          anchor="right"
          open={props.contentDrawerOpen}
          onClose={props.handleContentDrawerClick}
          PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
          BackdropProps={{
            style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
          }}
          ModalProps={{
            container: props.refContainer,
            style: { position: 'absolute', zIndex: 99 },
            disableEnforceFocus: true,
            keepMounted: true,
          }}>
          <Fragment>
            {props.contentDrawerOpen && (
              <Box
                sx={{
                  width: props.width,
                  height: '100%',
                  padding: '60px 8px 8px',
                }}>
                <Box
                  sx={{
                    height: '100%',
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Box
                    sx={{
                      padding: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                      }}>
                      {t('Contents')}
                    </Typography>
                    <Box flex={1} />
                    <IconButton onClick={props.handleContentDrawerClick}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                  <Paper
                    sx={{
                      flex: 1,
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Box
                      sx={{
                        overflow: 'auto',
                        padding: 3,
                        gap: 2,
                      }}>
                      {props.contents.map((content, cIndex) => {
                        if (!content.disabled) {
                          partLetterNumRef = partLetterNumRef + 1
                        }

                        return (
                          <Box
                            key={`drawer-content-${cIndex}`}
                            sx={{
                              padding: 1,
                              cursor: content.disabled ? 'inherit' : 'pointer',
                              ...(props.currentStep === cIndex && {
                                backgroundColor: '#f2f2f2',
                              }),
                              ...(content.disabled && {
                                color: '#f2f2f2',
                              }),
                            }}
                            onClick={() => {
                              if (!content.disabled) {
                                props.onContentClick(cIndex)
                                if (matchDownMd) {
                                  props.handleContentDrawerClick()
                                }
                              }
                            }}>
                            {(content.disabled
                              ? ''
                              : String.fromCharCode(partLetterNumRef) + ' / ') + content.title}
                          </Box>
                        )
                      })}
                    </Box>
                  </Paper>
                </Box>
              </Box>
            )}
          </Fragment>
        </Drawer>
      )
    }

    return (
      <Fragment>
        <IconItem>
          <IconButton className={commonStyles.svgIcon} onClick={handleContentDrawerClick}>
            <img src="/img/listing-number.svg" alt="list" />
          </IconButton>
        </IconItem>
        <ContentDrawer
          refContainer={() => containerRef.current}
          contentDrawerOpen={contentDrawerOpen}
          handleContentDrawerClick={handleContentDrawerClick}
          contents={props.components.map((component) => ({
            title: component.partTitle,
            disabled: component.disabled,
          }))}
          onContentClick={(index) => {
            setActiveStep(index)
            var numref = 0
            props.components
              .filter((x, idx) => idx < index)
              .map((x) => {
                if (!x.disabled) {
                  numref += 1
                }
              })
            partLetterNumRef.current = 65 + numref
          }}
          currentStep={activeStep}
          width={matchDownMd ? '100%' : 500}
        />
      </Fragment>
    )
  }

  const CommentItem = () => {
    const [commentDrawerOpen, setCommentDrawerOpen] = useState<boolean>(false)

    const handleCommentDrawerClick = () => {
      setCommentDrawerOpen((open) => !open)
    }

    const CommentDrawer = (props: {
      refContainer?: Element | (() => Element | null) | null | undefined
      commentDrawerOpen: boolean
      handleCommentDrawerClick: () => void
      globalState: GlobalStateProps
      formOnLoadCommentList?: () => Promise<Comment[]>
      formOnSubmitComment?: (comment: string) => Promise<void>
      width: string | number
    }) => {
      const { setRequest, isLoading } = useAPIFetch()

      const [comments, setComments] = useState<Comment[]>([])

      const { t } = useTranslation()

      useEffect(() => {
        if (props.commentDrawerOpen) {
          reloadComments()
        }
      }, [props.commentDrawerOpen])

      const reloadComments = () => {
        setRequest({
          callback: async () => {
            if (props.formOnLoadCommentList) {
              await props.formOnLoadCommentList().then((commentList) => {
                setComments(commentList.filter((x) => x.status))
              })
            }
          },
        })
      }

      return (
        <Drawer
          anchor="right"
          open={props.commentDrawerOpen}
          onClose={props.handleCommentDrawerClick}
          PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
          BackdropProps={{
            style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
          }}
          ModalProps={{
            container: props.refContainer,
            style: { position: 'absolute', zIndex: 99 },
            disableEnforceFocus: true,
            keepMounted: true,
          }}>
          <Fragment>
            {props.commentDrawerOpen && (
              <Box
                sx={{
                  width: props.width,
                  height: '100%',
                  padding: '60px 8px 8px',
                }}>
                <Box
                  sx={{
                    height: '100%',
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Box
                    sx={{
                      padding: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                      }}>
                      {t('Comments')}
                    </Typography>
                    <Box flex={1} />
                    <IconButton onClick={props.handleCommentDrawerClick}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                  <Paper
                    sx={{
                      flex: 1,
                      overflowX: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Box
                      sx={{
                        overflowY: 'auto',
                        padding: 3,
                        gap: 2,
                        flex: 1,
                      }}>
                      {isLoading && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <CircularProgress />
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}>
                        {comments.map((c) => (
                          <Box sx={{ borderRadius: '5px', marginTop: '15px', boxShadow: 2 }}>
                            <CommentItemTitle
                              backgroundColor={
                                c.commentType === 'Approve'
                                  ? '#87C458'
                                  : c.commentType === 'Reject'
                                  ? '#FF5A5A'
                                  : c.commentType === 'Cancel'
                                  ? '#878686'
                                  : '#E8E8E8'
                              }>
                              <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline">
                                <Grid item>
                                  <Typography fontWeight="bold">{c.commentType}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography fontWeight="bold" textAlign="left">
                                    Status: {c.formStatus.formStatusName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CommentItemTitle>
                            <CommentItemContent>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    minHeight: '68px',
                                    padding: '10px 20px',
                                    alignItems: 'center',
                                    gap: '8px',
                                    position: 'relative',
                                    cursor: 'pointer',
                                  }}>
                                  <Box
                                    component="img"
                                    alt="Varadise"
                                    src={avatar}
                                    sx={{ width: '2.8rem', height: '2.8rem', borderRadius: '50%' }}
                                  />

                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'baseline',
                                      flex: 1,
                                      width: '100%',
                                    }}>
                                    <Box className={commonStyles.fillBox}>
                                      <Box sx={{ display: 'flex' }}>
                                        <Typography
                                          sx={{
                                            fontSize: 10,
                                          }}>
                                          {GetValueWithKey(
                                            c.createdBy,
                                            props.globalState.userMetaList ?? [],
                                          )}
                                        </Typography>
                                        <Box sx={{ flex: 1 }} />
                                        <Typography
                                          sx={{
                                            fontSize: 10,
                                          }}>
                                          {moment(c.createdAt).format()}
                                        </Typography>
                                      </Box>
                                      <Typography>{c.comment}</Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </CommentItemContent>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <CommentInput
                      formOnSubmitComment={async (comment: string) => {
                        if (props.formOnSubmitComment) {
                          await props.formOnSubmitComment(comment)
                          reloadComments()
                        }
                      }}
                    />
                  </Paper>
                </Box>
              </Box>
            )}
          </Fragment>
        </Drawer>
      )
    }

    return (
      <Fragment>
        <IconItem>
          <IconButton
            className={commonStyles.svgIcon}
            disabled={props.disableComment}
            data-disabled={props.disableComment}
            onClick={handleCommentDrawerClick}>
            <img src="/img/chat.svg" alt="comment" />
          </IconButton>
        </IconItem>
        <CommentDrawer
          refContainer={() => containerRef.current}
          commentDrawerOpen={commentDrawerOpen}
          handleCommentDrawerClick={handleCommentDrawerClick}
          formOnLoadCommentList={props.formOnLoadCommentList}
          globalState={globalState}
          formOnSubmitComment={props.formOnSubmitComment}
          width={matchDownMd ? '100%' : 500}
        />
      </Fragment>
    )
  }

  //#region form history
  const HistoryItem = () => {
    const [formHistoryDrawerOpen, setFormRemainingItemDrawerOpen] = useState<boolean>(false)
    const [formHistories, setFormHistories] = useState<FormHistory[]>([])
    const reloadFormHistories = () => {
      if (props.formOnLoadFormHistoryList) {
        props
          .formOnLoadFormHistoryList()
          .then((formHistoryList) => {
            setFormHistories(formHistoryList.filter((x) => x.status))
          })
          .catch((err) => {
            console.error('Error getting form histories from form', err)
            setFormHistories([])
          })
      } else {
        setFormHistories([])
      }
    }

    const handleFormHistoryDrawerClick = () => {
      setFormRemainingItemDrawerOpen((open) => !open)
      if (formHistoryDrawerOpen) {
        setFormHistories([])
      } else {
        reloadFormHistories()
      }
    }

    const FormHistoryDrawer = (props: {
      refContainer?: Element | (() => Element | null) | null | undefined
      formHistoryDrawerOpen: boolean
      handleFormHistoryDrawerClick: () => void
      formHistories: FormHistory[]
      width: number | string
      globalState: GlobalStateProps
    }) => {
      const { t } = useTranslation()
      const FormStepConnector = styled(StepConnector)(({ theme }) => ({
        [`& .${stepConnectorClasses.line}`]: {
          margin: 'auto auto auto 12.5px',
        },
      }))

      const FormStepIconRoot = styled('div')<{
        state: { first?: boolean }
      }>(({ state }) => ({
        zIndex: 1,
        backgroundColor: '#e6e6e6',
        width: state.first ? 50 : 30,
        height: state.first ? 50 : 30,
        marginLeft: state.first ? 0 : 10,
        marginRight: state.first ? 0 : 10,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
      }))

      const FormStepComponent = (props: StepIconProps) => {
        return (
          <FormStepIconRoot state={{ first: props.active === true }}>
            {String(props.icon)}
          </FormStepIconRoot>
        )
      }

      return (
        <Drawer
          anchor="right"
          open={props.formHistoryDrawerOpen}
          onClose={props.handleFormHistoryDrawerClick}
          PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
          BackdropProps={{
            style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
          }}
          ModalProps={{
            container: props.refContainer,
            style: { position: 'absolute', zIndex: 99 },
            disableEnforceFocus: true,
            keepMounted: true,
          }}>
          <Fragment>
            {props.formHistoryDrawerOpen && (
              <Box
                sx={{
                  width: props.width,
                  height: '100%',
                  padding: '60px 8px 8px',
                }}>
                <Box
                  sx={{
                    height: '100%',
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Box
                    sx={{
                      padding: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                      }}>
                      {t('History')}
                    </Typography>
                    <Box flex={1} />
                    <IconButton onClick={props.handleFormHistoryDrawerClick}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                  <Paper
                    sx={{
                      flex: 1,
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Box
                      sx={{
                        overflow: 'auto',
                        padding: 3,
                        gap: 2,
                      }}>
                      <Stepper orientation="vertical" connector={<FormStepConnector />}>
                        {props.formHistories.map((formHistory, fhIndex) => {
                          return (
                            <Step key={`drawer-form-history-${fhIndex}`}>
                              <StepLabel
                                StepIconComponent={FormStepComponent}
                                StepIconProps={{ icon: props.formHistories.length - fhIndex }}>
                                <Box>
                                  <Typography
                                    sx={{
                                      color: 'black',
                                    }}>
                                    {formHistory.statusName}
                                  </Typography>
                                  <Typography sx={{ color: 'black', fontSize: 9 }}>
                                    {`${GetValueWithKey(
                                      formHistory.createdBy,
                                      props.globalState.userMetaList ?? [],
                                    )}`}
                                  </Typography>
                                  <Typography sx={{ color: 'black', fontSize: 9 }}>
                                    {formHistory.createdAt
                                      ? moment(formHistory.createdAt).format()
                                      : ''}
                                  </Typography>
                                </Box>
                              </StepLabel>
                            </Step>
                          )
                        })}
                      </Stepper>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            )}
          </Fragment>
        </Drawer>
      )
    }

    return (
      <Fragment>
        <IconItem>
          <IconButton className={commonStyles.svgIcon} onClick={handleFormHistoryDrawerClick}>
            <img src="/img/history.svg" alt="history" />
          </IconButton>
        </IconItem>
        <FormHistoryDrawer
          refContainer={() => containerRef.current}
          formHistoryDrawerOpen={formHistoryDrawerOpen}
          handleFormHistoryDrawerClick={handleFormHistoryDrawerClick}
          formHistories={formHistories}
          globalState={globalState}
          width={matchDownMd ? '100%' : 500}
        />
      </Fragment>
    )
  }

  const RemainingItem = () => {
    const [formRemaingingItemDrawerOpen, setFormRemainingItemDrawerOpen] = useState<boolean>(false)

    const handleFormRemainingItemDrawerClick = () => {
      setFormRemainingItemDrawerOpen((open) => !open)
    }

    const FormRemainingItemDrawer = (props: {
      refContainer?: Element | (() => Element | null) | null | undefined
      formRemaingingItemDrawerOpen: boolean
      handleFormRemainingItemDrawerClick: () => void
      width: number | string
      globalState: GlobalStateProps
      onExportDocument?: (event: any) => void
    }) => {
      const { t } = useTranslation()

      return (
        <Drawer
          anchor="right"
          open={props.formRemaingingItemDrawerOpen}
          onClose={props.handleFormRemainingItemDrawerClick}
          PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
          BackdropProps={{
            style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
          }}
          ModalProps={{
            container: props.refContainer,
            style: { position: 'absolute', zIndex: 99 },
            disableEnforceFocus: true,
            keepMounted: true,
          }}>
          <Fragment>
            {props.formRemaingingItemDrawerOpen && (
              <Box
                sx={{
                  width: props.width,
                  height: '30%',
                  padding: '60px 8px 8px',
                }}>
                <Box
                  sx={{
                    height: '100%',
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  {/*  <IconButton onClick={props.handleFormRemainingItemDrawerClick}>
                    <ArrowForwardIosIcon />
                  </IconButton> */}

                  <Divider />
                  <Paper
                    sx={{
                      flex: 1,
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Box
                      sx={{
                        padding: 2,
                      }}>
                      <Button onClick={props.onExportDocument}>
                        <FileDownloadIcon />
                        <Typography>Export</Typography>
                      </Button>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            )}
          </Fragment>
        </Drawer>
      )
    }

    return (
      <Fragment>
        <IconItem>
          <IconButton className={commonStyles.svgIcon}>
            <img src="/img/navigation-menu.svg" alt="more" />
          </IconButton>
        </IconItem>
        <FormRemainingItemDrawer
          refContainer={() => containerRef.current}
          formRemaingingItemDrawerOpen={formRemaingingItemDrawerOpen}
          handleFormRemainingItemDrawerClick={handleFormRemainingItemDrawerClick}
          globalState={globalState}
          width={matchDownMd ? '100%' : 140}
          onExportDocument={props.onExportDocument}
        />
      </Fragment>
    )
  }

  const FormSwitcher = () => (
    <Box
      sx={{
        height: '98%',
      }}>
      <FormDialog
        key={'dialog'}
        open={dialogContext.isOpen}
        onClose={handleDialogClose}
        toolbarStyle={dialogContext.toolbarStyle}
        title={dialogContext.title}
        buttons={dialogContext.buttons}
        children={dialogContext.children}
      />

      <Box
        sx={{
          display: 'flex',
          position: 'fixed',
          backgroundColor: 'white',
          zIndex: 100,
          marginTop: '-5px',
        }}
        className={commonStyles.fillBox}
        boxShadow="2">
        <IconItem>
          <IconButton className={commonStyles.svgIcon} onClick={handleBackBtn}>
            <img src="/img/prev.svg" alt="back" />
          </IconButton>
        </IconItem>
        <IconItem sx={{ flexGrow: 1 }}></IconItem>

        <IconItem>
          <IconButton
            className={commonStyles.svgIcon}
            onClick={props.onExportDocument ?? props.onExportDocument}
            data-disabled={!(props.onExportDocument !== undefined) || props.disableExport}
            disabled={!(props.onExportDocument !== undefined) || props.disableExport}>
            <img src="/img/export.svg" alt="export" />
          </IconButton>
        </IconItem>

        <IconItem>
          <IconButton
            className={commonStyles.svgIcon}
            onClick={(event: any) => {
              if (props.onSubFormComplete) {
                handleOnComplete()
              } else if (props.formOnSave) {
                props.formOnSave(event)
              }
            }}
            data-disabled={props.disableSave || props.endOfFlow}
            disabled={props.disableSave || props.endOfFlow}>
            <img src="/img/save.svg" alt="save" />
          </IconButton>
        </IconItem>

        <IconItem>
          <Tooltip title="Cancel">
            <IconButton
              className={commonStyles.svgIcon}
              disabled={props.disableDelete || props.endOfFlow || !props.formCanCancel}
              onClick={handleCancel}>
              <ClearRoundedIcon />
            </IconButton>
          </Tooltip>
        </IconItem>

        {(props.showTrashBin ?? true) && (
          <IconItem>
            <Tooltip title="Delete">
              <IconButton
                className={commonStyles.svgIcon}
                disabled={props.disableDelete || props.endOfFlow}
                data-disabled={props.disableDelete || props.endOfFlow}
                onClick={handleDelete}>
                <img src="/img/trash.svg" alt="trash" />
              </IconButton>
            </Tooltip>
          </IconItem>
        )}

        <ContentItem />

        <CommentItem />

        <HistoryItem />

        <RemainingItem />
      </Box>
      <Grid key={'switcher'}>
        <LoadingPopup open={props.isLoading || false} />

        <Box
          sx={{
            position: 'relative',
            // height: 'calc(100vh - 230px)',
            height: '100%',
          }}>
          {props.components.length > 0 ? (
            <StyledBox ref={containerRef}>
              {activeStep > props.components.length && activeStep <= 0 ? (
                <Typography sx={{ fontWeight: 500 }}>Stepper fault</Typography>
              ) : (
                <Box
                  sx={{
                    height: '100%',
                    overflow: 'auto',
                  }}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <PartTitleTypography
                      variant={matchDownMd ? 'h6' : 'h4'}
                      sx={{ fontWeight: 'bold', zIndex: '1 !important' }}>
                      {String.fromCharCode(partLetterNumRef.current) +
                        ' / ' +
                        props.components[activeStep].partTitle}
                    </PartTitleTypography>
                    {props.components[activeStep].additionalBtn}
                  </Stack>
                  {props.components[activeStep].component}
                </Box>
              )}

              <Grid>
                <StyledDivider />
                <Box sx={{ height: '8px' }} />
                <ButtonGroupBox>
                  <SwitcherButton
                    disabled={partLetterNumRef.current <= 65}
                    onClick={handleBack}
                    startIcon={<ArrowBackIosNewIcon />}>
                    {t('Previous')}
                  </SwitcherButton>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <SwitcherButton
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={handleNext}
                    disabled={
                      props.components[activeStep].onNextDisabled ||
                      (activeStep === props.components.length - 1 && props.disableSave) ||
                      (activeStep === props.components.length - 1 && props.disableComplete) ||
                      (activeStep === props.components.length - 1 && props.endOfFlow)
                    }>
                    {(props.components.filter((c) => !c.disabled).length <
                      props.components.length &&
                      activeStep === props.components.filter((c) => !c.disabled).length - 1) ||
                    activeStep === props.components.length - 1
                      ? t('Complete')
                      : t('Next')}
                  </SwitcherButton>
                </ButtonGroupBox>
              </Grid>
            </StyledBox>
          ) : (
            <Fragment>no part components</Fragment>
          )}
        </Box>
      </Grid>
    </Box>
  )

  return [FormSwitcher, handleOnComplete]
}

export default useFormSwitcher
