import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Control, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Divider, Typography } from '@mui/material'
import uuid from 'react-uuid'
import { find, get } from 'lodash'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDate from '@components/form/date'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormFreeText from '@components/form/freeText'
import FormSelect from '@components/form/select'
import FormSignatureHistories from '@components/form/signatureHistories'
import FormTable from '@components/form/table'
import FormTypography from '@components/form/typography'
import FormMultipleSelect from '@components/form/multipleSelect'
import { DevTool } from '@hookform/devtools'
import useAPIFetch from '@hooks/useAPIFetch'
import useFormSwitcher from '@hooks/useFormSwitcher'
import useLocalStorage from '@hooks/useLocalStorage'
import useQuery from '@hooks/useQuery'
import { FormPartComponents } from '@models/common'
import { FormContext } from '@providers/formStateProvider'
import { GlobalContext } from '@providers/globalStore'
import FormService from '@services/form.service'
import FormQSService from '@services/formService/form.QS.service'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormQSModel, InitQSForm, QSGeneralOptions } from '@services/model/form/form.QS.model'
import { NavigateTo } from '@utils/navigate'
import { QueryStepParser } from '@utils/queryStepParser'
import { GetDistrictOptions } from '@utils/districtOptions'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetWorksOrderOptions } from '@utils/worksOrderOptions'

const UUID = uuid()
export default function QSForm() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  let { id: formId } = useParams<string>() // extracting id from params

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()

  const isMounted = useRef(false)
  const [qsGeneralOptions, setQsGeneralOptions] = useState<QSGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'QS')?.value ?? {},
  )

  useEffect(() => {
    if (!isMounted.current) {
      if (formId) reload()
      else {
        setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
        setValue(
          'baseForm.contractTitle',
          globalState?.contractList?.find((contract) => contract.id === globalState.contractNoId)
            ?.contractTitle,
        )
        setValue('baseForm.teamId', userInfo.teams)
        setValue('baseForm.districtId', userInfo.districts)
        isMounted.current = true
      }

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })
    }

    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formId, formStateAction, globalAction])

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormQSService.GetQSForm(formId)
            .then(async (f) => {
              if (f) reset(f)

              if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
                globalAction({
                  type: 'selectContract',
                  contractNoId: f.baseForm.contractNoId,
                })

                globalAction({
                  type: 'selectWorkGroup',
                  workGroupId: get(
                    find(globalState.contractList, { id: f.baseForm.contractNoId }),
                    'workGroups.0.id',
                    0,
                  ),
                })
              }

              setValue(
                'baseForm.contractTitle',
                globalState?.contractList?.find((x) => x.id === f.baseForm.contractNoId)
                  ?.contractTitle,
              )
              globalAction({
                type: 'changeFormStatus',
                formStatus: f.baseForm.formStatusName,
              })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/quarterly-summary/all-record')
            })
        }

        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    setQsGeneralOptions(globalState.formOptionsList?.find(({ key }) => key === 'QS')?.value ?? {})
  }, [globalState.formOptionsList])

  const { control, watch, setValue, getValues, reset } = useForm<FormQSModel>({
    defaultValues: { ...InitQSForm },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  // watching
  const [formStatus, signatureBase64, startDateOfPeriod, endDateOfPeriod, contractNoId] = watch([
    'baseForm.formStatus',
    'signatureBase64',
    'startDateOfPeriod',
    'endDateOfPeriod',
    'baseForm.contractNoId',
  ])

  const worksOrderIdOption =
    globalState.worksOrderList?.map((wo) => ({
      key: wo.id,
      value: wo.workOrderNo,
    })) ?? []

  const formPermission = getValues('baseForm.formPermission')

  //#region part a

  const QSPartA = (): FormPartComponents => {
    const [disable, setDisable] = useState<boolean>(true)

    const uneditable =
      formStatus !== FormStatusEnum.FORM_QS_DRAFT && formStatus !== FormStatusEnum.FORM_QS_REJECTED

    useEffect(() => {
      const subscription = watch((data) => {
        if (
          data.startDateOfPeriod &&
          data.baseForm?.contractNoId &&
          data.baseForm.contractNoId > 0 &&
          data.baseForm.workOrderId &&
          data.baseForm.workOrderId > 0 &&
          data.typeOfMmWorks &&
          data.typeOfMmWorks > 0
        ) {
          if (disable) {
            setDisable(false)
          }
        } else {
          setDisable(true)
        }
      })
      return () => subscription.unsubscribe()
    }, [watch])

    const ScheduleOfRateField = ({ control }: { control: Control<FormQSModel> }) => {
      /* const [workOrderId] = useWatch({ control, name: ['baseForm.workOrderId'] })

          setValue('scheduleOfRate', qsGeneralOptions.woiSorList?.find(workSor => workSor.woId === workOrderId)?.sorItemNo ?? 0) */

      return (
        <FormField fieldName="Schedule of Rate">
          <FormController controllerProps={{ control, name: 'scheduleOfRate' }}>
            <FormFreeText
              textfieldProps={{ type: 'number', inputProps: { min: 1 }, fullWidth: true }}
              uneditable={uneditable}
            />
          </FormController>
        </FormField>
      )
    }

    const WorkOrderNo = () => {
      const [teamId, districtId] = useWatch({
        control,
        name: ['baseForm.teamId', 'baseForm.districtId'],
      })

      return (
        <FormField fieldName="Works Order No">
          {uneditable ? (
            <FormTypography value={getValues('baseForm.workOrderNo')} />
          ) : (
            <FormController
              controllerProps={{
                name: 'baseForm.workOrderId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={GetWorksOrderOptions(
                  globalState?.worksOrderList?.filter((workOrder) => workOrder.typeOfWorks) || [],
                  teamId,
                  districtId,
                )}
                uneditable={uneditable}
              />
            </FormController>
          )}
        </FormField>
      )
    }

    const handleOnLoadNextPageData = async () => {
      if (formStatus === FormStatusEnum.FORM_QS_DRAFT) {
        const firstPageData = {
          contractNoId: getValues('baseForm.contractNoId'),
          workOrderId: getValues('baseForm.workOrderId'),
          typeOfMmWorks: getValues('typeOfMmWorks'),
          startDateOfPeriod: getValues('startDateOfPeriod'),
          endDateOfPeriod: getValues('endDateOfPeriod'),
          scheduleOfRate: getValues('scheduleOfRate'),
          scheduleAmount: getValues('scheduleAmount'),
        }

        setRequest({
          callback: async () => {
            await FormQSService.GetQSData({ ...firstPageData }).then((resp) => {
              reset({
                ...getValues(),
                entitlementToMonthlySumId: resp.entitlementToMonthlySumId,
                entitlementToMonthlySum: resp.entitlementToMonthlySum,
                totalPerformanceStandard: resp.totalPerformanceStandard,
                qsPerformanceStandard: resp.qsPerformanceStandard,
                qsDefaultNotice: resp.qsDefaultNotice,
                qsSubPerformanceStandard: resp.qsSubPerformanceStandard,
                qsDefaultNoticeSummary: resp.qsDefaultNoticeSummary,
              })
            })
          },
        })
      }
    }

    return {
      partTitle: t('General Information'),
      component: (
        <FormContainer fkey={`${UUID}-qs-a`}>
          <FormField fieldName="Contract No.">
            <FormController controllerProps={{ control, name: 'baseForm.contractNoId' }}>
              <FormSelect
                fullWidth
                options={
                  globalState?.contractList?.map((x) => ({
                    key: x.id,
                    value: x.contractNo,
                  })) || []
                }
                uneditable={true}
                additionalChangeAction={async (_, newValue) => {
                  if (newValue && newValue.key) {
                    const found = globalState?.contractList?.find((x) => x.id === newValue.key)
                    if (found) setValue('baseForm.contractTitle', found.contractTitle)
                  }
                }}
              />
            </FormController>
          </FormField>

          <FormField fieldName="Contract Title">
            <FormController controllerProps={{ control, name: 'baseForm.contractTitle' }}>
              <FormTypography />
            </FormController>
          </FormField>

          <FormField fieldName="Team">
            <FormController
              controllerProps={{
                control,
                name: 'baseForm.teamId',
              }}>
              <FormMultipleSelect
                options={uneditable ? globalState.teamList || [] : GetTeamOptions(userInfo.teams)}
                uneditable={uneditable}
                additionalChangeAction={(event) => setValue('baseForm.workOrderId', undefined)}
              />
            </FormController>
          </FormField>

          <FormField fieldName="District">
            <FormController
              controllerProps={{
                control,
                name: 'baseForm.districtId',
              }}>
              <FormMultipleSelect
                options={
                  uneditable
                    ? globalState.districtList || []
                    : GetDistrictOptions(userInfo.districts)
                }
                uneditable={uneditable}
                additionalChangeAction={(event) => setValue('baseForm.workOrderId', undefined)}
              />
            </FormController>
          </FormField>

          <WorkOrderNo />

          <FormField fieldName="Type of M&M Works">
            <FormController controllerProps={{ control, name: 'typeOfMmWorks' }}>
              <FormSelect
                fullWidth
                uneditable={uneditable}
                options={
                  qsGeneralOptions?.typeOfMmWorks?.filter(({name = ''}) => ![
                    "Maintenance of Road Marking, Road Drainage System, Detailed Inspections in Hygienic Conditions",
                    "Maintenance of Road Marking, Road Drainage System and Detailed Inspections"
                  ].includes(name ))?.map((x) => ({
                    key: x.id,
                    value: x.name,
                  })) || []
                }
              />
            </FormController>
          </FormField>

          <FormField fieldName="Start Date of Performance Period">
            <FormController controllerProps={{ control, name: 'startDateOfPeriod' }}>
              <FormDateTimePicker
                type="month"
                uneditable={uneditable}
                additionalChangeAction={(value) => {
                  const endDate = moment(value).add(2, 'months').startOf('month').toDate()
                  setValue('endDateOfPeriod', endDate)
                }}
              />
            </FormController>
          </FormField>

          <FormField fieldName="End Date of Performance Period">
            <FormController controllerProps={{ control, name: 'endDateOfPeriod' }}>
              <FormDate visable={endDateOfPeriod !== undefined} format="MMMM YYYY" />
            </FormController>
            <Typography sx={{ ...(endDateOfPeriod !== undefined && { marginLeft: 1 }) }}>
              {endDateOfPeriod === undefined
                ? t('Please pick a month above')
                : `(3 ${t('months after the start date')})`}
            </Typography>
          </FormField>

          <ScheduleOfRateField control={control} />
        </FormContainer>
      ),
      onNextDisabled: disable,
      onNext: handleOnLoadNextPageData,
    }
  }

  //#endregion

  //#region part b
  const QSPartB = (): FormPartComponents => {
    return {
      partTitle: t('Performance Standard Summary'),
      component: (
        <FormContainer fkey={`${UUID}-qs-b`}>
          <FormField fieldName="Table">
            <Typography>{t('Performance Standard')}</Typography>
          </FormField>

          <FormField fieldName="Works Order No.">
            <FormController controllerProps={{ control, name: 'baseForm.workOrderId' }}>
              <FormTypography type="select" options={worksOrderIdOption} />
            </FormController>
          </FormField>

          <FormField fieldName="Period">
            <Typography>
              {`${moment(startDateOfPeriod).format('MMMM YYYY')} - ${moment(endDateOfPeriod).format(
                'MMMM YYYY',
              )}`}
            </Typography>
          </FormField>

          <FormTable
            fieldArrayProps={{ control, name: 'qsPerformanceStandard' }}
            columns={[
              {
                field: 'monthYearOfPerformanceStandard',
                displayName: 'Month of M&M Works Performance',
                type: 'displayDate',
                dateFormat: 'MMM YYYY',
              },
              {
                field: 'dateOfAudit',
                displayName: 'Date of Audit',
                type: 'displayDate',
                dateFormat: 'DD MMM YYYY',
              },
              {
                field: 'neaNo',
                displayName: 'EA Notification No.',
              },
              {
                field: 'noOfDefectiveLaneSection',
                displayName: 'No. of Defective Lane Section',
              },
              {
                field: 'noOfLaneSectionAudited',
                displayName: 'No. of Lane Section Audited',
              },
            ]}
            rowFilter={() => true}
            hiddenItemNo
            removeAddButton
            height={50}
          />

          <Divider />

          <FormTable
            fieldArrayProps={{ control, name: 'qsSubPerformanceStandard' }}
            columns={[
              {
                field: 'monthYearOfPerformanceStandard',
                displayName: 'Month of M&M Works Performance',
                type: 'displayDate',
                dateFormat: 'MMM YYYY',
              },
              {
                field: '',
                displayName: 'Performance Standard',
                type: 'custom',
                render: (field) => <>{`${field['performanceStandard']}%`}</>,
              },
              {
                field: '',
                displayName: 'Entitled Payment',
                type: 'custom',
                render: (field) => <>{`${field['entitlementToMonthlySum']}%`}</>,
              },
              {
                field: 'scheduleOfRate',
                displayName: 'Schedule of Rate',
              },
              {
                field: 'accumulatedIpPayable',
                displayName: 'Accumulated IP Payable',
              },
            ]}
            rowFilter={() => true}
            hiddenItemNo
            removeAddButton
            height={50}
          />
        </FormContainer>
      ),
    }
  }

  //#endregion

  //#region part c
  const QSPartC = (): FormPartComponents => {
    return {
      partTitle: t('Default Notice Summary'),
      component: (
        <FormContainer fkey={`${UUID}-qs-c`}>
          <FormField fieldName="Table">
            <Typography>{t('Default Notice')}</Typography>
          </FormField>

          <FormField fieldName="Works Order No.">
            <FormController controllerProps={{ control, name: 'baseForm.workOrderId' }}>
              <FormTypography type="select" options={worksOrderIdOption} />
            </FormController>
          </FormField>

          <FormField fieldName="Period">
            <Typography>{`${moment(startDateOfPeriod).format('MMMM YYYY')} - ${moment(
              endDateOfPeriod,
            ).format('MMMM YYYY')}`}</Typography>
          </FormField>

          <FormField fieldName="Schedule Amount">
            <FormController controllerProps={{ control, name: 'scheduleAmount' }}>
              <Typography>{`HK$${getValues('scheduleAmount') || 0}`}</Typography>
            </FormController>
          </FormField>

          <FormTable
            fieldArrayProps={{ control: control, name: 'qsDefaultNotice' }}
            columns={[
              {
                field: 'dnMonth',
                displayName: 'Month',
              },
              {
                field: '',
                displayName: 'DN Reference No.',
                type: 'custom',
                render: (field) => <>{field['nonCompliance']?.dnRefNo}</>,
              },
              {
                field: '',
                displayName: 'Type of Non-compliance',
                type: 'custom',
                render: (field) => <>{field['nonCompliance']?.typeOfNonCompliance}</>,
              },
              {
                field: 'totalNoOfDn',
                displayName: 'Total No. of DN',
              },
              {
                field: 'totalDnAmount',
                displayName: 'Total DN Amount',
                type: 'custom',
                render: (field) => (
                  <>{!isNaN(field['totalDnAmount']) ? `HK$${field['totalDnAmount']}` : ''}</>
                ),
              },
              {
                field: 'totalRefundedAmount',
                displayName: 'Total Refunded Amount',
                type: 'custom',
                render: (field) => (
                  <>
                    {!isNaN(field['totalRefundedAmount'])
                      ? `HK$${field['totalRefundedAmount']}`
                      : ''}
                  </>
                ),
              },
              {
                field: 'totalPayment',
                displayName: 'Total Payment Deduction',
                type: 'custom',
                render: (field) => (
                  <>{!isNaN(field['totalPayment']) ? `HK$${field['totalPayment']}` : ''}</>
                ),
              },
              {
                field: 'remark',
                displayName: 'Remark',
                type: 'string',
                uneditable:
                  formStatus !== FormStatusEnum.FORM_QS_DRAFT &&
                  formStatus !== FormStatusEnum.FORM_QS_REJECTED,
              },
            ]}
            rowFilter={() => true}
            hiddenItemNo
            removeAddButton
            height={50}
          />

          <FormTable
            fieldArrayProps={{ control: control, name: 'qsDefaultNoticeSummary' }}
            columns={[
              {
                field: 'dnMonth',
                displayName: 'Month',
              },
              {
                field: '',
                displayName: 'Monthly Deduction Amount',
                type: 'custom',
                render: (field) => <>{`HK$${field['monthlyDeductionAmount']}`}</>,
              },
              {
                field: '',
                displayName: 'Max. Deduction Amount',
                type: 'custom',
                render: (field) => <>{`HK$${field['maxDeductionAmount']}`}</>,
              },
              {
                field: '',
                displayName: 'Actual Deduction Amount',
                type: 'custom',
                render: (field) => <>{`HK$${field['actualDeductionAmount']}`}</>,
              },
              {
                field: '',
                displayName: 'Accumulated IP Payable',
                type: 'custom',
                render: (field) => <>{`HK$${field['accumulatedIpPayable']}`}</>,
              },
              {
                field: '',
                displayName: 'Net IP to be Paid This Month',
                type: 'custom',
                render: (field) => <>{`HK$${field['netIpToBePaidThisMonth']}`}</>,
              },
            ]}
            rowFilter={() => true}
            hiddenItemNo
            removeAddButton
            height={50}
          />
        </FormContainer>
      ),
    }
  }

  //#endregion

  //#region part d
  const QSPartD = (): FormPartComponents => {
    return {
      partTitle: t('Submission Confirmation'),
      component: (
        <FormContainer fkey={`${UUID}-qs-d`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission?.endOfFlow}
            userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
    }
  }

  //#endregion

  //#endregion

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    setValue('signatureBase64', signatureBase64)
    let ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment

    if (isReject)
      setRequest({
        callback: async () => {
          await FormQSService.RejectQSForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/quarterly-summary/all-record')
          })
        },
      })
    else
      setRequest({
        callback: async () => {
          await FormQSService.ApproveQSForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/quarterly-summary/all-record')
          })
        },
      })
  }

  const handleOnSave = async () =>
    setRequest({
      callback: async () => {
        await FormQSService.SaveQSForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)

          NavigateTo(navigate, '/quarterly-summary/:id', { id: resp.formId })

          formId = resp.formId
          reload()

          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })

  const handleOnDelete = async () =>
    setRequest({
      callback: async () => {
        await FormQSService.DeleteQSForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/quarterly-summary/all-record')
          })
          .catch((err) =>
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: null,
            }),
          )
      },
    })

  const onLoadNotifyList = async (isRejcet: boolean) =>
    await FormService.GetNotifyList(
      'QS',
      getValues('baseForm.formId'),
      isRejcet,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )

  const onLoadHistoryList = async () =>
    await FormService.GetHistoryList(getValues('baseForm.formId'))

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('QUARTERLY SUMMARY'),
    components: [QSPartA(), QSPartB(), QSPartC(), QSPartD()],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () =>
      await FormService.GetCommentList(getValues('baseForm.formId')),
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <DevTool control={control} />
    </Fragment>
  )
}
