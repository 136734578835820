import { GetFormStatusId } from '@utils/getFormStatusId'
import { get, some } from 'lodash'
import moment from 'moment'

export const getNfFormData = (data: any, data2: any, optionList?: any) => {
  const { globalState, dnGeneralOptions, nfGeneralOptions } = optionList
  let formDn = data['formDn']
    ?.filter((dn) => dn.baseForm.formStatusId === 64)
    ?.map((x, index) => {
      return {
        itemNo: index + 1,
        dnNo: x.dnNo,
        typeOfNonCompliance: dnGeneralOptions?.typeOfNonComplianceList?.find(
          (dn) => dn.id === x.nonComplianceId,
        )?.typeOfNonCompliance,
        amountOfDeduction: dnGeneralOptions?.typeOfNonComplianceList?.find(
          (dn) => dn.id === x.nonComplianceId,
        )?.amountOfDeduction,
        ncPeriod:
          moment(x.startAt).format(moment.defaultFormat).toString() +
          ' - ' +
          moment(x.endAt).format(moment.defaultFormat).toString(),
      }
    })

  let partC_action = data['baseForm']['formStatusHistories']?.findLast((x) =>
    GetFormStatusId(globalState?.formStatusList, ['FORM_NF_DRAFT'])?.includes(x.formStatusId),
  )
  let partC2_action = data['baseForm']['formStatusHistories']?.findLast((x) =>
    GetFormStatusId(globalState?.formStatusList, ['FORM_NF_REVIEW'])?.includes(x.formStatusId),
  )
  let partD_action = data['baseForm']['formStatusHistories']?.findLast((x) =>
    GetFormStatusId(globalState?.formStatusList, [
      'FORM_NF_RECTIFICATION',
      'FORM_NF_RECTIFICATION_REJECTED',
    ])?.includes(x.formStatusId),
  )
  let partE_action = data['baseForm']['formStatusHistories']?.findLast((x) =>
    GetFormStatusId(globalState?.formStatusList, ['FORM_NF_REVIEW_RECTIFICATION'])?.includes(
      x.formStatusId,
    ),
  )

  const defectCategory = nfGeneralOptions?.defectCategory?.find(
    (x) => x.id === data['defectCategoryId'],
  )
  const defectOption = defectCategory?.defectOptions?.find((x) => x.id === data['defectOptionId'])

  return {
    contractor:
      globalState?.contractList?.find((x) => x.id === data['baseForm']['contractNoId'])
        ?.contractor ?? '',
    contractTitle:
      globalState?.contractList?.find((x) => x.id === data['baseForm']['contractNoId'])
        ?.contractTitle ?? '',
    NF_no: data['nfNo'] ?? '',
    contract_no:
      globalState?.contractList?.find((x) => x.id === data['baseForm']['contractNoId'])
        ?.contractNo ?? '',
    EI_no: data['eiNo'] ?? 'N/A',
    ei_date_time:
      data['dateOfCheck'] === undefined
        ? ''
        : moment(data['dateOfCheck']).format(moment.defaultFormat).toString() ?? '', //*** */
    locationAddress: data['location']['locationAddress'] ?? '',
    locationRemarks: data['locationRemark'] ?? '',
    ei_emergency_no: data2 ? data2['emergencySerialNo'] ?? '' : 'N/A',
    ei_icc: data2 ? data2['iccNo'] ?? '' : 'N/A',
    works_order_no: data['baseForm']['workOrderNo'] ?? '' ?? '',

    ei_works_order_no: data2 ? data2['baseForm']['workOrderNo'] ?? '' ?? '' : '',

    complaint_checkbox: data2 ? (data2['caseSourceId'] === 33 ? '☑' : '☐') : '☐',
    works_order_checkbox: data2 ? (data2['caseSourceId'] === 34 ? '☑' : '☐') : '☐',
    staff_checkbox: data2 ? (data2['caseSourceId'] === 35 ? '☑' : '☐') : '☐',
    others_checkbox: data2 ? (data2['caseSourceId'] === 36 ? '☑' : '☐') : '☐',

    defect_category: defectCategory?.name ?? '',
    identified_defect: `${defectOption?.defectCode ?? ''}. ${defectOption?.description ?? ''}`,
    date_of_check:
      data['dateOfCheck'] === undefined
        ? ''
        : moment(data['dateOfCheck']).format(moment.defaultFormat).toString() ?? '',
    time_limit:
      nfGeneralOptions?.defectCategory
        ?.find((category) => data['defectCategoryId'] === category.id)
        ?.defectOptions?.find((option) => data['defectOptionId'] === option.id)
        ?.timeLimits?.find((x) => x.id === data['timeLimitId'])?.timeLimit ??
      '' ??
      '',
    nf_partA_remarks: data['remarks'] ?? '',
    ei_otherInfo: data2 ? data2['otherInfo'] ?? '' : 'N/A',
    due_date:
      data['dueDate'] === undefined
        ? ''
        : moment(data['dueDate']).format(moment.defaultFormat).toString() ?? '',

    partC_full_name:
      globalState.userMetaFullList?.find((x) => x.userId === partC_action?.actionBy)?.userName ??
      '',
    partC_designation:
      globalState.userMetaFullList?.find((x) => x.userId === partC_action?.actionBy)?.position ??
      '',
    partC_datetime:
      partC_action === undefined
        ? ''
        : moment(partC_action?.actionAt).format(moment.defaultFormat).toString() ?? '',

    partC2_full_name:
      globalState.userMetaFullList?.find((x) => x.userId === partC2_action?.actionBy)?.userName ??
      '',
    partC2_designation:
      globalState.userMetaFullList?.find((x) => x.userId === partC2_action?.actionBy)?.position ??
      '',
    partC2_datetime:
      partC2_action === undefined
        ? ''
        : moment(partC2_action?.actionAt).format(moment.defaultFormat).toString() ?? '',
    rectification_date:
      data['rectifications']?.at(-1)?.rectificationDate === undefined
        ? ''
        : moment(data['rectifications']?.at(-1)?.rectificationDate)
            .format(moment.defaultFormat)
            .toString() ?? '',

    partD_remarks: data['rectifications']?.at(-1)?.otherInfo ?? '',
    partD_full_name:
      globalState.userMetaFullList?.find((x) => x.userId === partD_action?.actionBy)?.userName ??
      '',
    partD_designation:
      globalState.userMetaFullList?.findLast((x) => x.userId === partD_action?.actionBy)
        ?.position ?? '',
    partD_datetime:
      partD_action === undefined
        ? ''
        : moment(partD_action?.actionAt).format(moment.defaultFormat).toString() ?? '',

    partE_full_name:
      globalState.userMetaFullList?.find((x) => x.userId === partE_action?.actionBy)?.userName ??
      '',
    partE_designation:
      globalState.userMetaFullList?.findLast((x) => x.userId === partE_action?.actionBy)
        ?.position ?? '',
    partE_datetime:
      partE_action === undefined
        ? ''
        : moment(partE_action?.actionAt).format(moment.defaultFormat).toString() ?? '',

    partE_accepted:
      some(data['baseForm']['formStatusHistories'], {
        formStatusId: get(
          GetFormStatusId(globalState?.formStatusList, ['FORM_NF_RECTIFICATION_REJECTED']),
          '0',
        ),
      }) && get(data, 'baseForm.formStatus') !== 'FORM_NF_APPROVED'
        ? 'unaccepted'
        : 'accepted',
    defect_photo: data['images'] === null ? [] : data['images'] ?? [],
    rectification_photo: data['rectifications']?.at(-1)?.['rectificationImages'] ?? [],
    formDn: formDn ?? [],
    defect_map: data['locationMapBase64'] ?? '',
    site_condition_photo: data2
      ? data2['siteImages'] === null
        ? []
        : data2['siteImages'] ?? []
      : [],
    //ei_map: data2?(data2["locationMapBase64"] === null ? "":data2["locationMapBase64"]):"",
  }
}
