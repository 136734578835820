import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { Bar, Doughnut } from 'react-chartjs-2'
import { ChartData, ChartDataset } from 'chart.js'
import { subDays } from 'date-fns'
import { Box, Stack, styled, useTheme } from '@mui/system'
import { Button, Card, Grid, Paper, useMediaQuery } from '@mui/material'
import useAPIFetch from '@hooks/useAPIFetch'
import { GlobalContext } from '@providers/globalStore'
import FormController from '@components/form/controller'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import LoadingPopup from '@components/form/LoadingDialog'
import { formIconProps } from '@components/form/overview/icon'
import FormIntroductionBox, { formBriefInfo } from '@components/form/overview/introductionBox'
import { DnaDashboardReq } from '@services/model/form/form.DNA.model'
import FormDNAService from '@services/formService/form.DNA.service'
import { getFormColor } from '@utils/getFormColor'
import { colorArray } from '@utils/colorList'
import DNAInfo from '../../../mockData/fetchData/formInfo/form.DNA.json'
import { NavigateTo } from '@utils/navigate'

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}))

const Overview = ({ isDashboard }: { isDashboard?: boolean }) => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const { state: globalState, hasRightByCatAndCode, userInfo } = useContext(GlobalContext)

  const navigate = useNavigate()
  const [formInfo, setFormInfo] = useState(DNAInfo)

  const { setRequest, isLoading } = useAPIFetch()

  const [barData, setBarData] = useState<ChartData<'bar', Array<number>, string>>()
  const [doughnutData, setDoughnutData] = useState<ChartData<'doughnut', Array<number>, string>>()

  const { control, getValues, setValue } = useForm<DnaDashboardReq>({
    defaultValues: {
      teamId: [], // need to wait for globalState.teamList to be set
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
    },
  })

  const [teamId, startDate, endDate] = useWatch({
    control,
    name: ['teamId', 'startDate', 'endDate'],
  })

  // form introduction
  const fIconProps: formIconProps = {
    shortName: formInfo.shortName,
    shortNameBgColor: getFormColor(formInfo.shortName, formInfo.module),
    SvgSx: { fontSize: '70px' },
  }

  const fInfo: formBriefInfo = {
    id: formInfo.id,
    workflowId: formInfo.workflowId,
    name: formInfo.name,
    description: formInfo.description,
    workflowInvolvement: formInfo.workflowInvolvement,
    linkedLists: formInfo.linkedLists,
  }

  const fetchChartData = () =>
    setRequest({
      callback: async (cancelToken: any) => {
        FormDNAService.GetDnaDashboard(getValues(), cancelToken).then(async (dnDashboard) => {
          const doughnutDatasets: Array<ChartDataset<'doughnut', Array<number>>> = [
            {
              label: 'Type of Non-Compliance', // Label for the dataset
              data: dnDashboard.typesOfNonCompliance.map<number>((d) => d.total),
              backgroundColor: colorArray, // An array of colors for each slice
            },
          ]

          const uniqueDates = [...new Set(dnDashboard.formStatuses.map((item) => item.date))]
          const formStatuses = [...new Set(dnDashboard.formStatuses.map((item) => item.formStatus))]

          const barDatasets = formStatuses.map<ChartDataset<'bar', Array<number>>>(
            (status, index) => ({
              label: status,
              data: uniqueDates.map((date) => {
                let item = dnDashboard.formStatuses.find(
                  (d) => d.date === date && d.formStatus === status,
                )
                return item ? item.total : 0 // Return total if the item exists, otherwise return 0
              }),
              backgroundColor: colorArray[index], // Get a color for each status
            }),
          )

          setDoughnutData({
            labels: dnDashboard.typesOfNonCompliance.map((d) => d.typeOfNonCompliance),
            datasets: doughnutDatasets,
          })

          setBarData({
            labels: uniqueDates,
            datasets: barDatasets,
          })
        })
      },
    })

  useEffect(() => {
    if (globalState.teamList)
      setValue(
        'teamId',
        userInfo.teams.filter((team) =>
          globalState.teamList?.map((team) => team.key).includes(team),
        ),
      )

    fetchChartData() // initial fetch
  }, [globalState.teamList])

  useEffect(() => {
    if (teamId.length > 0) fetchChartData()
  }, [teamId, startDate, endDate])

  return (
    <Box
      sx={{
        padding: matchUpMd ? '40px' : '10px',
        gap: '12px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Grid container>
        {!isDashboard && (
          <Grid item md={12} lg={4}>
            <StyledCard variant="outlined">
              <FormIntroductionBox
                formIconProps={fIconProps}
                formBriefInfo={fInfo}
                hasSideBtn={true}
                onClickSideBtnHandler={() => NavigateTo(navigate, '/application-of-default-notice-for-non-compliance')}></FormIntroductionBox>
            </StyledCard>
          </Grid>
        )}

        <Grid item md={12} lg={isDashboard ? 12 : 8}>
          <Grid container sx={{ overflow: 'hidden', width: 'inherit' }}>
            <FormField
              fieldName="Team"
              additionalChildren={
                <Stack sx={{ flexDirection: matchUpMd ? 'row' : 'column' }}>
                  <Button
                    onClick={(event: any) => {
                      setValue('teamId', globalState.teamList?.map((team) => team.key) ?? [], {
                        shouldDirty: true,
                      })
                    }}
                    sx={{ minWidth: '10%' }}>
                    Select All
                  </Button>
                  <Button
                    onClick={(event: any) => {
                      setValue('teamId', [], { shouldDirty: true })
                    }}
                    sx={{ minWidth: '10%' }}>
                    Unselect All
                  </Button>
                </Stack>
              }>
              <FormController controllerProps={{ control, name: 'teamId' }}>
                <FormMultipleSelect
                  options={
                    globalState.teamList?.filter(({ key }) => userInfo.teams.includes(key)) || []
                  }
                />
              </FormController>
            </FormField>

            <FormField fieldName="Start Date">
              <FormController controllerProps={{ control, name: 'startDate' }}>
                <FormDateTimePicker type="date" />
              </FormController>
            </FormField>
            <FormField fieldName="End Date">
              <FormController controllerProps={{ control, name: 'endDate' }}>
                <FormDateTimePicker type="date" />
              </FormController>
            </FormField>
          </Grid>
        </Grid>
      </Grid>

      <LoadingPopup open={isLoading} />

      <Paper sx={{ padding: matchUpMd ? '24px' : '8px' }}>
        <Stack sx={{ flexDirection: matchUpMd ? 'row' : 'column' }}>
          {barData && (
            <Box width={matchUpMd ? '65%' : '100%'}>
              <Bar
                data={barData}
                options={{
                  scales: {
                    x: { stacked: true },
                    y: {
                      stacked: true,
                      min: 0,
                      ticks: { stepSize: 1 },
                    },
                  },
                }}
              />
            </Box>
          )}

          {doughnutData && (
            <Box width={matchUpMd ? '35%' : '100%'}>
              <Doughnut
                data={doughnutData}
                options={{
                  scales: {
                    x: { stacked: true },
                    y: {
                      stacked: true,
                      min: 0,
                      ticks: { stepSize: 1 },
                    },
                  },
                }}
              />
            </Box>
          )}
        </Stack>
      </Paper>
    </Box>
  )
}

export default Overview
