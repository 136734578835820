import { BaseModel } from './base.model'

export interface GroupType extends BaseModel {
    id: number
    name: string
    groups: Group[]
    contractNoId?: number
}

export interface GroupByContract extends BaseModel {
    id: number
    name: string
    shortTerm?: string
    status: boolean
    groupTypeId: number
    tag?: string
}

export interface GroupByContractItems extends BaseModel {
    items: GroupByContract[]
}

export const InitGroupForm = {
    name: '',
    type: '',
    contractNoId: 0
}

export interface GroupFormModel extends BaseModel {
    name: string
    shortTerm?: string
    tag?: string
    type: string
    contractNoId: number
    status: boolean
    meetupLocation: {
        meetupLocation: string
        id: number
        districtId: number
        status: boolean
    }[]
}

export interface Group extends BaseModel {
    id: number
    name: string
    shortTerm: string
    groupTypeId: number
}