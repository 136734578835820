import moment from 'moment'
import { GetFormStatusId } from '@utils/getFormStatusId'
import { find, findLast, first, get } from 'lodash'
import { FormStatusEnum } from '@services/model/form/form.model'

export const getScrFormData = (data: any, optionList?: any) => {
  const {
    globalState,
    scrGeneralOptions,
    resourceType = [],
    constructionPlant = [],
    wipType = [],
    workSCompletionList = [],
    essentialOperationList = [],
    inSituTestList = [],
    inSituSampleList = [],
    sitePhotoCategory = [],
    worksStatus = [],
    yesNo = [],
    satisfactory = [],
  } = optionList

  const { contractList = [], districtList = [], teamList = [], userMetaFullList = [] } = globalState
  const { tradeList = [] } = scrGeneralOptions

  const scrDraft = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
    return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_SCR_DRAFT])?.includes(
      formStatusId,
    )
  })
  const scrReview = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
    return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_SCR_REVIEW])?.includes(
      formStatusId,
    )
  })
  const scrApproved = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
    return GetFormStatusId(globalState?.formStatusList, [
      FormStatusEnum.FORM_SCR_APPROVED,
    ])?.includes(formStatusId)
  })

  return {
    contractor:
      contractList?.find((x) => x.id === data['baseForm']['contractNoId'])?.contractor ?? '',
    contract_no:
      contractList?.find((x) => x.id === data['baseForm']['contractNoId'])?.contractNo ?? '',
    works_order_no: data['baseForm']['workOrderNo'] ?? '' ?? '',
    scr_no: get(data, 'scrNo', ''),
    district: get(data, 'baseForm.districtId', [])
      .filter((district) => {
        return !!get(find(districtList, { key: district }), 'value')
      })
      .map((district) => {
        return get(find(districtList, { key: district }), 'value')
      })
      .join(','),
    team: get(data, 'baseForm.teamId', [])
      .filter((team) => {
        return !!get(find(teamList, { key: team }), 'value')
      })
      .map((team) => {
        return get(find(teamList, { key: team }), 'value')
      })
      .join(', '),
    locationDetails: get(data, 'fromScrCrw.locationDetails'),
    formDate: get(data, 'formDate')
      ? moment(get(data, 'formDate')).format(moment.defaultFormat).toString() ?? ''
      : '',

    workStatus: get(find(worksStatus, { key: get(data, 'worksStatusId') }), 'value', ''),
    actualActivities: get(find(wipType, { key: get(data, 'wipTypeSubOptionId', 0) }), 'value', ''),

    siteProgressForLabour: get(data, 'siteProgressForLabour', []).map(
      ({ tradeId, resourceTypeId, ...rest }) => ({
        ...rest,
        trade: get(find(tradeList, { id: tradeId }), 'trade', ''),
        resourceType: get(find(resourceType, { key: resourceTypeId }), 'value', ''),
      }),
    ),
    siteProgressForPlant: get(data, 'contractorResources', []).map(
      ({ idleCodeId, noOfPlant, noOfIdlePlant, resourceTypeId, ...rest }) => ({
        ...rest,
        resourceType: get(find(constructionPlant, { key: resourceTypeId }), 'value', ''),
      }),
    ),

    remarks: get(data, 'remarks', ''),

    workCompletion: get(
      find(workSCompletionList, { key: get(data, 'workCompletionId') }),
      'value',
      '',
    ),
    mosquitoBp: get(find(yesNo, { key: get(data, 'mosquitoBpId') }), 'value', ''),
    loaWastesAbsent: get(find(yesNo, { key: get(data, 'loaWastesAbsentId') }), 'value', ''),
    mpStockpiled: get(find(yesNo, { key: get(data, 'mpStockpiledId') }), 'value', ''),
    lsgSatisfaction: get(find(satisfactory, { key: get(data, 'lsgSatisfactionId') }), 'value', ''),
    qowSatisfaction: get(find(satisfactory, { key: get(data, 'qowSatisfactionId') }), 'value', ''),
    emSatisfaction: get(find(satisfactory, { key: get(data, 'emSatisfactionId') }), 'value', ''),

    essentialOperation: get(data, 'essentialOperation')
      .map((opt) => get(find(essentialOperationList, { key: opt }), 'value', ''))
      .join(', '),
    inSituTest: get(data, 'inSituTest')
      .map((opt) => get(find(inSituTestList, { key: opt }), 'value', ''))
      .join(', '),
    inSituSample: get(data, 'inSituSample')
      .map((opt) => get(find(inSituSampleList, { key: opt }), 'value', ''))
      .join(', '),
    hiddenWorkCheck: get(data, 'hiddenWorkCheck', '') ? 'Yes' : 'No',

    draftBy: userMetaFullList?.find((x) => x.userId === scrDraft?.actionBy)?.userName ?? '',
    draftByPost: userMetaFullList?.find((x) => x.userId === scrDraft?.actionBy)?.position ?? '',
    draftDatetime: get(scrDraft, 'actionAt')
      ? moment(get(scrDraft, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
      : '',
    reviewBy: userMetaFullList?.find((x) => x.userId === scrReview?.actionBy)?.userName ?? '',
    reviewByPost: userMetaFullList?.find((x) => x.userId === scrReview?.actionBy)?.position ?? '',
    reviewByDatetime: get(scrReview, 'actionAt')
      ? moment(get(scrReview, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
      : '',
    approvedBy: userMetaFullList?.find((x) => x.userId === scrApproved?.actionBy)?.userName ?? '',
    approvedByPost:
      userMetaFullList?.find((x) => x.userId === scrApproved?.actionBy)?.position ?? '',
    approvedByDatetime: get(scrApproved, 'actionAt')
      ? moment(get(scrApproved, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
      : '',
    summaryMapBase64: get(data, 'summaryMapBase64', ''),

    scrPhotos: get(data, 'scrPhotos', []).map((photo) => {
      return {
        photoCategory: get(
          find(sitePhotoCategory, { key: get(photo, 'photoCategoryId') }),
          'value',
          '',
        ),
        image: first(get(photo, 'images', [])),
      }
    }),
  }
}
