import apiGateway from '@utils/axiosInterceptor'
import BaseSaveResponse from '../model/base.model'
import {
  FormDNAListModel,
  FormDNAModel,
  DNAGeneralOptions,
  DNAListResponse,
  DNAFilterOptions,
  DnaDashboard,
  DnaDashboardReq,
} from '../model/form/form.DNA.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'

const GetGeneralOptions = async () => {
  return await apiGateway.get<DNAGeneralOptions>(`/api/v1/forms/DNA/options`).then((rep) => {
    return rep.data
  })
}

const GetDNAForm = async (formId: string) => {
  return await apiGateway.get<FormDNAModel>(`api/v1/forms/DNA/${formId}`).then((rep) => {
    return rep.data
  })
}

const GetDNAList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<DNAListResponse>(`api/v1/forms/DNA/${myRecordOnly ? 'my-action' : 'list'}`, req, {
      cancelToken: cancelToken,
    })
    .then((rep) => {
      return rep.data
    })
}

const GetDNAFilterOption = async (showMyRecord?: boolean) => {
  let api = `api/v1/forms/DNA/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<DNAFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveDNAForm = async (req: FormDNAModel) => {
  if (req.baseForm.formId === '') {
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/DNA', req).then((rep) => {
      return rep.data
    })
  } else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/DNA/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveDNAForm = async (req: FormDNAModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DNA/${req.baseForm.formId}/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const RejectDNAForm = async (req: FormDNAModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DNA/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteDNAForm = async (req: FormDNAModel) => {
  return await apiGateway.delete<BaseFormResponse>(`api/v1/forms/DNA/${req.baseForm.formId}`)
}

const GetDnaDashboard = async (req: DnaDashboardReq, cancelToken: any): Promise<DnaDashboard> => {
  return await apiGateway
    .post<DnaDashboard>('api/v1/forms/DNA/dashboard', req, { cancelToken: cancelToken })
    .then((resp) => resp.data)
}

export default {
  GetDNAForm,
  GetDNAList,
  GetDNAFilterOption,
  SaveDNAForm,
  ApproveDNAForm,
  RejectDNAForm,
  DeleteDNAForm,
  GetGeneralOptions,
  GetDnaDashboard,
}
