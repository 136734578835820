import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormContainer from '@components/form/container'
import { FormPartComponents } from '@models/common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import FormSignatureHistories from '@components/form/signatureHistories'
import uuid from 'react-uuid'
import { InitDNForm, DNGeneralOptions, FormDNModel } from '@services/model/form/form.DN.model'
import { useNavigate, useParams } from 'react-router-dom'
import useFormSwitcher from '@hooks/useFormSwitcher'
import FormDNService from '@services/formService/form.DN.service'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { FormNFModel, NFGeneralOptions } from '@services/model/form/form.NF.model'
import FormService from '@services/form.service'
import { FormStatusEnum } from '@services/model/form/form.model'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ValidationToast from '@components/form/ValidationToast'
import getFormValidationSchema from '@utils/formValidationSchema'
import { canvasExport } from '@utils/canvasExport'
import { PartA } from './part-a'
import PartB from './part-b'
import { find, get, last } from 'lodash'
import useLocalStorage from '@hooks/useLocalStorage'
import FormNFService from '@services/formService/form.NF.service'
import { exportDocument } from '@utils/documentExporter'
import { GetBase64FromUrl, GetImageDimensions } from '@utils/image'
import formDNAService from '@services/formService/form.DNA.service'

const UUID = uuid()

export default function DNForm() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  // extracting id from params
  let { id: formId } = useParams<string>()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const num = QueryStepParser(query.get('num'))

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_DN_ISSUED, FormStatusEnum.FORM_DN_ENDORSEMENT_REJECTED],
      validationSchema: yup.object().shape({
        baseForm: yup.object().required(),
        appealImages: yup.array().when('baseForm.formStatus', {
          is: FormStatusEnum.FORM_DN_ISSUED,
          then: yup.array().nullable().min(1).required(t('Appeal Images is required')),
          otherwise: yup.array().nullable(),
        }),
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.FORM_DN_DRAFT,
        FormStatusEnum.FORM_DN_REVIEW,
        FormStatusEnum.FORM_DN_ISSUED,
        FormStatusEnum.FORM_DN_ISSUE_REJECTED,
        FormStatusEnum.FORM_DN_APPEALED,
        FormStatusEnum.FORM_DN_APPEAL_ENDORSED,
        FormStatusEnum.FORM_DN_APPEAL_APPROVAL_REJECTED,
        FormStatusEnum.FORM_DN_APPEAL_SUCCESS,
      ],
      validationSchema: yup.object(),
    },
  ]

  const { control, watch, setValue, getValues, trigger, reset } = useForm<FormDNModel>({
    defaultValues: { ...InitDNForm },
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaConditions,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  // watching form status, signature change
  const formPermission = getValues('baseForm.formPermission')
  const formStatus = watch('baseForm.formStatus')

  const [dnGeneralOptions, setGeneralOptions] = useState<DNGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'DN')?.value ?? {},
  )
  const [nfGeneralOptions, setNFGeneralOptions] = useState<NFGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'NF')?.value ?? {},
  )
  const isMounted = useRef(false)
  const [isLoadingAPI, setIsLoadingAPI] = useState<boolean>(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()

  useEffect(() => {
    globalAction({
      type: 'changeFormStatus',
      formStatus: getValues('baseForm.formStatusName'),
    })
  }, [formStatus])

  useEffect(() => {
    if (!isMounted.current) {
      reloadOthers()

      if (formState.form && formState.formType === 'NF') {
        let formDn: FormDNModel = { ...InitDNForm }
        const _parentForm = formState.form as FormNFModel

        if (num !== undefined) {
          // old form
          if (_parentForm.formDn && _parentForm.formDn[num]) {
            formDn = { ..._parentForm.formDn[num] }
          }
        }

        formDn.baseForm.contractNoId = _parentForm.baseForm.contractNoId
        formDn.baseForm.workOrderId = _parentForm.baseForm.workOrderId

        formDn.baseForm.teamId = _parentForm.baseForm.teamId
        formDn.baseForm.districtId = _parentForm.baseForm.districtId

        formDn.parentNf = {
          location: _parentForm.location,
          roadName: _parentForm.roadName,
          dateOfCheck: _parentForm.dateOfCheck,
          nfNo: _parentForm.nfNo,
        }

        reset({ ...formDn })
        // set parent form into this state
        setParentForm({ ...formState.form })
      } else if (formId) {
        reload()
      } else {
        // new form
        reset({ ...InitDNForm })
      }
    }

    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      }) // nav bar remove status
    }
  }, [formId, num, formStateAction])

  const reloadOthers = async () => {
    setOptionRequest({
      callback: async () => {
        await FormDNService.GetGeneralOptions().then(async (list) => {
          if (list) setGeneralOptions(list)
        })
      },
    })
  }

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormDNService.GetDNForm(formId)
            .then(async (f) => {
              if (f.baseForm.formStatus === FormStatusEnum.FORM_DN_CANCEL) {
                const commentList = await FormService.GetCommentList(get(f, 'baseForm.formId'))

                const cancelStatus = last(get(f, 'baseForm.formStatusHistories', []))
                f.dnCancelledBy = get(cancelStatus, 'createdByUserName')
                f.dateOfCancellation = get(cancelStatus, 'createdAt')
                f.cancelComment = get(find(commentList, { commentType: 'Cancel' }), 'comment', '')
              }

              if (f) reset(f)

              if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
                globalAction({
                  type: 'selectContract',
                  contractNoId: f.baseForm.contractNoId,
                })

                globalAction({
                  type: 'selectWorkGroup',
                  workGroupId: get(
                    find(globalState.contractList, { id: f.baseForm.contractNoId }),
                    'workGroups.0.id',
                    0,
                  ),
                })
              }
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/default-notice-for-non-compliance/all-record')
            })
        }

        isMounted.current = true
      },
    })
  }

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return

    setValue('signatureBase64', signatureBase64)
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment

    if (isReject) {
      setRequest({
        callback: async () => {
          await FormDNService.RejectDNForm(ff).then(() => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/default-notice-for-non-compliance/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          await FormDNService.ApproveDNForm(ff).then(() => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/default-notice-for-non-compliance/all-record')
          })
        },
      })
    }
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormDNService.SaveDNForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/default-notice-for-non-compliance/:id', {
            id: resp.formId,
          })

          formId = resp.formId

          reload()

          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () =>
    setRequest({
      callback: async () => {
        await FormDNService.DeleteDNForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/default-notice-for-non-compliance/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
          })
      },
    })

  const handleOnCancel = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    setValue('signatureBase64', signatureBase64)
    let ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment

    setRequest({
      callback: async () => {
        await FormDNService.CancelDNForm(ff)
          .then(() => {
            enqueueSnackbar(t('Record Cancelled'), { variant: 'success' })
            NavigateTo(navigate, '/default-notice-for-non-compliance/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
          })
      },
    })
  }

  const handleExportDocument = async () => {
    setIsLoadingAPI(true)
    const parentType = getValues('from')
    const parentFormId = getValues('baseForm.parentFormUuid')
    if (parentFormId !== '' && parentFormId !== undefined) {
      const parentForm =
        parentType === 'NF'
          ? await FormNFService.GetNFForm(parentFormId)
          : await formDNAService.GetDNAForm(parentFormId)
      const contractNo = get(
        find(globalState?.contractList, {
          id: get({ parentForm: parentForm, ...getValues() }, 'baseForm.contractNoId'),
        }),
        'contractNo',
        '',
      )
      console.log('rez', { parentForm: parentForm, ...getValues() })
      const templateName = '/templates/DN_template.docx'
      const templateNameOld = '/templates/DN_template_old.docx'
      setRequest({
        callback: async () => {
          // Special handling for different template
          if (
            contractNo === '04/HY/2020' ||
            contractNo === '05/HY/2020' ||
            contractNo === '12/HY/2019' ||
            contractNo === '18/HY/2020'
          ) {
            console.log('Entered old default template.')
            await exportDocument(
              templateNameOld,
              getValues('dnNo') ?? 'DN',
              { parentForm: parentForm, ...getValues() },
              {},
              {
                nfGeneralOptions: nfGeneralOptions,
                globalState: globalState,
                dnGeneralOptions: dnGeneralOptions,
              },
            )
          } else {
            console.log('Entered default template.')
            await exportDocument(
              templateName,
              getValues('dnNo') ?? 'DN',
              { parentForm: parentForm, ...getValues() },
              {},
              {
                nfGeneralOptions: nfGeneralOptions,
                globalState: globalState,
                dnGeneralOptions: dnGeneralOptions,
              },
            )
          }
        },
      })
    }

    setIsLoadingAPI(false)
  }

  const [parentForm, setParentForm] = useState<FormNFModel | undefined>()
  const hasParentForm =
    parentForm !== undefined &&
    getValues('baseForm.parentFormId') !== '' &&
    getValues('baseForm.parentFormId') !== undefined
  const handleParentFormSubmission = parentForm &&
    (parentForm.baseForm.formStatus === FormStatusEnum.FORM_NF_DRAFT ||
      parentForm.baseForm.formStatus === FormStatusEnum.FORM_NF_REVIEW ||
      parentForm.baseForm.formStatus === FormStatusEnum.FORM_NF_ISSUE_REJECTED)
  

  const mapRef = useRef<HTMLUListElement>(null)
  const saveTargetMap = async () => {
    let targetBase64 = await canvasExport(mapRef)
    setValue('locationMapBase64', targetBase64)
  }

  const DNPartA = (): FormPartComponents => {
    return {
      partTitle: t('General information'),
      component: (
        <PartA
          control={control}
          getValues={getValues}
          formStatus={formStatus}
          dnGeneralOptions={dnGeneralOptions}
          globalState={globalState}
          hasParentForm={hasParentForm}
          userInfo={userInfo}
          isMounted={isMounted}
          ref={mapRef}
        />
      ),
      onNext: () => saveTargetMap(),
    }
  }

  const DNPartB = (): FormPartComponents => {
    const readonly = !(
      formStatus === FormStatusEnum.FORM_DN_ISSUED ||
      formStatus === FormStatusEnum.FORM_DN_ENDORSEMENT_REJECTED
    )

    return {
      partTitle: t('Appeal of Cancellation of DN'),
      component: (
        <PartB
          control={control}
          formStatus={formStatus}
          setValue={setValue}
          getValues={getValues}
          dnGeneralOptions={dnGeneralOptions}
        />
      ),
      disabled:
        formStatus === FormStatusEnum.FORM_DN_DRAFT ||
        formStatus === FormStatusEnum.FORM_DN_REVIEW ||
        formStatus === FormStatusEnum.FORM_DN_ISSUE_REJECTED,
    }
  }

  const DNPartC = (): FormPartComponents => {
    return {
      partTitle: t('Submission Confirmation'),
      component: (
        <FormContainer fkey={`${UUID}-ei-d`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission?.endOfFlow}
            userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
      disabled:
        parentForm &&
        (parentForm.baseForm.formStatus === FormStatusEnum.FORM_NF_DRAFT ||
          parentForm.baseForm.formStatus === FormStatusEnum.FORM_NF_REVIEW ||
          parentForm.baseForm.formStatus === FormStatusEnum.FORM_NF_ISSUE_REJECTED),
    }
  }

  const onLoadNotifyList = async (isRejcet: boolean) => {
    var defailtNotifyList = await FormService.GetNotifyList(
      'DN',
      getValues('baseForm.formId'),
      isRejcet,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )
    return defailtNotifyList
  }

  const onLoadHistoryList = async () => {
    var defaultNotifyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultNotifyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('DEFAULT NOTICE FOR NON-COMPLIANCE'),
    components: [DNPartA(), DNPartB(), DNPartC()],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    formOnCancel: handleOnCancel,
    onExportDocument: handleExportDocument,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate,
    showTrashBin: false,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
    formCanCancel: formStatus === FormStatusEnum.FORM_DN_DRAFT,
    navigateBack: () => {
      if (parentForm) {
        let _parentForm: FormNFModel = { ...parentForm }
        formStateAction({ type: 'push', form: _parentForm, formType: 'DN' })
        NavigateTo(navigate, '/notification-form-of-defect/:formId', { formId }, { step: 0 })
      } else {
        formStateAction({ type: 'clear' })
        NavigateTo(navigate, '/default-notice-for-non-compliance/all-record')
      }
    },
    ...(parentForm && handleParentFormSubmission && {
      onSubFormComplete: () => {
        if (parentForm) {
          let _parentForm: FormNFModel = { ...parentForm }
          if (num !== undefined) {
            // old form
            _parentForm = {
              ...parentForm,
              formDn: parentForm.formDn?.map((form: FormDNModel, index: number) =>
                index === num ? getValues() : form,
              ),
            }
          } else {
            // new form
            _parentForm = {
              ...parentForm,
              formDn: [...(parentForm.formDn ?? []), getValues()],
            }
          }
          // insert this form to parent from
          formStateAction({ type: 'push', form: _parentForm, formType: 'NF' })
        } else {
          alert('something went wrong')
          formStateAction({ type: 'clear' })
        }

        NavigateTo(navigate, '/notification-form-of-defect/:formId', { formId }, { step: 0 })
      },
    }),
  })

  return (
    <Fragment>
      <FormSwitcher />
      <ValidationToast control={control} />
    </Fragment>
  )
}
