import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Control, useWatch, UseFormSetValue } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import { FormPartComponents, KeyValPair } from '@models/common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { FormWouModel, InitWOUForm, WouGeneralOptions } from '@services/model/form/form.WOU.model'
import { DevTool } from '@hookform/devtools'
import FormWOUService from '@services/formService/form.WOU.service'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import FormService from '@services/form.service'
import PartA from './part-a'
import PartB from './part-b'
import PartC from './part-c'
import PartD from './part-d'
import PartE from './part-e'
import PartF from './part-f'
import PartG from './part-g'
import ContractService from '@services/contract.service'
import { WorkOrderList } from '@services/model/contract.model'
import { FormStatusEnum } from '@services/model/form/form.model'
import { find, get } from 'lodash'
import useLocalStorage from '@hooks/useLocalStorage'

export default function WOUForm() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setContractNoRequest, isLoading: isLoadingContract } = useAPIFetch()
  const { enqueueSnackbar } = useSnackbar()
  const [wouGeneralOptions, setWouGeneralOptions] = useState<WouGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'WOU')?.value ?? {},
  )
  const [workOrderList, setWorksOrderList] = useState<WorkOrderList[]>()
  const isMounted = useRef<boolean>(false)
  const [disableNav, setDisableNav] = useState<boolean>(true) // for part A onNext disable

  const { control, watch, setValue, getValues, handleSubmit, reset, trigger } =
    useForm<FormWouModel>({
      defaultValues: { ...InitWOUForm },
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
    })

  const [formPermission, formStatus] = getValues(['baseForm.formPermission', 'baseForm.formStatus'])
  const uneditable =
    formStatus !== FormStatusEnum.FORM_WOU_DRAFT && formStatus !== FormStatusEnum.FORM_WOU_REJECTED
  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormWOUService.GetWOUForm(formId)
            .then(async (f) => {
              if (f) reset(f)

              if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
                globalAction({
                  type: 'selectContract',
                  contractNoId: f.baseForm.contractNoId,
                })

                globalAction({
                  type: 'selectWorkGroup',
                  workGroupId: get(
                    find(globalState.contractList, { id: f.baseForm.contractNoId }),
                    'workGroups.0.id',
                    0,
                  ),
                })
              }

              globalAction({
                type: 'changeFormStatus',
                formStatus: f.baseForm.formStatusName,
              })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/works-order-update/all-record')
            })
        }

        isMounted.current = true
      },
    })
  }

  const reloadOthers = async () => {
    setContractNoRequest({
      callback: async () => {
        await ContractService.GetWorkOrderListForWOU(storageContractId ?? '')
          .then((options) => setWorksOrderList(options))
          .catch((err) =>
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: null,
            }),
          )
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      reloadOthers()

      if (formId) reload()
      else setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })

      isMounted.current = true
    }

    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formId, formStateAction, globalAction])

  const handleOnLoadNextPageData = async () => {
    if (formStatus === FormStatusEnum.FORM_WOU_DRAFT && getValues('firstPageData') === true) {
      setRequest({
        callback: async () => {
          const list = await FormWOUService.GetWOIData(
            getValues('baseForm.workOrderId') ?? 0,
          ).catch(() => {
            enqueueSnackbar(t('Error in fetching Work order data, please try again'), {
              variant: 'error',
            })
            setDisableNav(true)
          })
          if (list) {
            console.log('list', list)
            setValue('typeOfMmWorks', list.typeOfMmWorks)
            setValue('sorItemNo', list.sorItemNo)
            setValue('letterOfSlowProgressIssued', list.letterOfSlowProgressIssued)
            setValue('slowProgressNumber', list.slowProgressNumber)
            setValue('letterOfOverdueProgressIssued', list.letterOfOverdueProgressIssued)
            setValue('attachmentForLetterOfSlowProgress', list.attachmentForLetterOfSlowProgress)
            setValue(
              'attachmentForLetterOfOverdueProgress',
              list.attachmentForLetterOfOverdueProgress,
            )
            setValue('letterOfConfirmation', list.letterOfConfirmation)
            setValue('attachmentForLetterOfNoEot', list.attachmentForLetterOfNoEot)
            setValue('extensionOfTimes', list.extensionOfTimes)
            setValue('siteInstructionValuations', list.siteInstructionValuations)
            setValue('urgencyLevelRequirementComplied', list.urgencyLevelRequirementComplied)
            setValue('actualUrgencyLevel', list.actualUrgencyLevel)
            setValue('sbv', list.sbv)
            setValue('lastInterimPayment', list.lastInterimPayment)
            setValue('relevantInformation', list.relevantInformation)
            setValue('relevantAttachments', list.relevantAttachments)
          }
        },
      })
      setValue('firstPageData', false)
      reset(getValues())
    }
  }

  const WOUPartA = (): FormPartComponents => {
    return {
      partTitle: t('General Information'),
      component: (
        <PartA
          control={control}
          globalState={globalState}
          workOrderList={workOrderList}
          setValue={setValue}
          watch={watch}
          setDisableNav={setDisableNav}
          uneditable={uneditable}
          formStatus={formStatus}
          options={wouGeneralOptions}
        />
      ),
      onNextDisabled: formStatus === FormStatusEnum.FORM_WOU_DRAFT && disableNav,
      onNext: handleOnLoadNextPageData,
    }
  }

  /* const WOUPartB = (): FormPartComponents => {
    return {
      partTitle: t('M&M Works Order Information'),
      component: <PartB control={ control } options={ wouGeneralOptions } useWatch={ useWatch } />,
      disabled : typeOfWorks === false
    }
  } */

  const WOUPartC = (): FormPartComponents => {
    return {
      partTitle: t('Site Supervision'),
      component: <PartC control={control} uneditable={uneditable} />,
    }
  }

  const WOUPartD = (): FormPartComponents => {
    return {
      partTitle: t('Extension of Time Assessment'),
      component: <PartD control={control} options={wouGeneralOptions} uneditable={uneditable} />,
    }
  }

  const WOUPartE = (): FormPartComponents => {
    return {
      partTitle: t('Site Instruction Valuation'),
      component: <PartE control={control} uneditable={uneditable} />,
    }
  }

  const WOUPartF = (): FormPartComponents => {
    return {
      partTitle: t('Request for Payment Batching'),
      component: (
        <PartF
          control={control}
          options={wouGeneralOptions}
          useWatch={useWatch}
          setValue={setValue}
          uneditable={uneditable}
        />
      ),
    }
  }

  const WOUPartG = (): FormPartComponents => {
    return {
      partTitle: t('Submission Confirmation'),
      component: (
        <PartG
          control={control}
          getValues={getValues}
          globalState={globalState}
          formPermission={formPermission}
          handleOnComplete={() => handleOnComplete()}
        />
      ),
    }
  }

  const onLoadNotifyList = async (isReject: boolean) => {
    var defaultNotifyList = await FormService.GetNotifyList(
      'WOU',
      getValues('baseForm.formId'),
      isReject,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
      [
        {
          key: 'woid',
          value: getValues('baseForm.workOrderId') || '',
        },
      ],
    )
    return defaultNotifyList
  }

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment

    if (isReject) {
      setRequest({
        callback: async () => {
          await FormWOUService.RejectWOUForm(ff)
            .then((resp) => {
              NavigateTo(navigate, '/works-order-update/all-record')
              enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
            })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          await FormWOUService.ApproveWOUForm(ff)
            .then((resp) => {
              NavigateTo(navigate, '/works-order-update/all-record')
              enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
            })
        },
      })
    }
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormWOUService.SaveWOUForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/works-order-update/all-record')

          formId = resp.formId
          // reload()

          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormWOUService.DeleteWOUForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/works-order-update/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
          })
      },
    })
  }

  const onLoadHistoryList = async () => {
    var defaultNotifyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultNotifyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('WORKS ORDER INFORMATION'),
    components: [WOUPartA(), WOUPartC(), WOUPartD(), WOUPartE(), WOUPartF(), WOUPartG()],
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading || isLoadingContract,
    disableSave: !formPermission?.canUpdate,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <DevTool control={control} />
    </Fragment>
  )
}
